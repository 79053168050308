<template>
  <!-- eslint-disable -->
	<div class="page d-flex flex-row flex-column-fluid">
		<div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
			<div class="toolbar py-5 py-lg-15" id="kt_toolbar">
				<div id="kt_toolbar_container" class="container-xxl d-flex flex-stack flex-wrap">
					<h3 class="text-white fw-bolder fs-2qx me-5">Strefa administratora</h3>
				</div>
			</div>
			<div id="kt_content_container" class="d-flex flex-column-fluid align-items-start container-xxl">
				<div class="content flex-row-fluid" id="kt_content">
					<div class="d-flex flex-column flex-xl-row">
            <div class="flex-column flex-lg-row-auto w-100 w-xl-325px mb-10">
              <div class="card card-flush">
                <div class="card-body pt-0 p-10">
                  <div class="d-flex flex-column pt-10 mb-10">
                    <router-link to="dashboard" class="fs-2 text-gray-800 text-hover-primary fw-bolder mb-1">
                      {{ organisation.name }}
                    </router-link>
                  </div>
                  <ul class="menu menu-column menu-pill menu-title-gray-700 menu-bullet-gray-300
                    menu-state-bg menu-state-bullet-primary fw-bolder fs-5 mb-10">
                    <SidebarLink to="/admin/dashboard" title="Kokpit" />
                    <SidebarLink to="/admin/requests" title="Wnioski o dostęp" />
                    <SidebarLink to="/admin/organisations" title="Konta klienta" />
                    <SidebarLink to="/admin/stats" title="Aktywne aplikacje" />
                  </ul>
                </div>
              </div>
            </div>
            <router-view />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SidebarLink from '../components/SidebarLink.vue';

export default {
  name: 'AdminDashboard',
  inject: ['$organisation', '$profile'],
  provide: ['requestsCount'],
  data() {
    return {
      timer: false,
      internalRequests: [],
      externalRequests: [],
      internalRequestsCount: false,
      externalRequestsCount: false
    }
  },
  mounted() {
    this.reload();
    this.timer = setInterval(this.reload, 60000);
  },
  beforeUnmount() {
  },
  methods: {
    reload: function() {
      var self = this;
      this.$root.$refs.api.get('requests?type=internal').then(function (response) {
        if (typeof response.data !== 'undefined' && Array.isArray(response.data))
        {
          self.internalRequests = response.data;
          self.internalRequestsCount = response.data.length;
        }
      })
      .catch(function (error) {
        console.log(error);
        self.internalRequests = [];
        self.internalRequestsCount = false;
      });
      this.$root.$refs.api.get('requests?type=external').then(function (response) {
        if (typeof response.data !== 'undefined' && Array.isArray(response.data))
        {
          self.externalRequests = response.data;
          self.externalRequestsCount = response.data.length;

        }
      })
      .catch(function (error) {
        console.log(error);
        self.externalRequests = [];
        self.externalRequestsCount = false;
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  computed: {
    organisation() {
      return this.$organisation();
    },
    profile() {
      return this.$profile();
    },
    requestsCount() {
      return this.internalRequestsCount + this.externalRequestsCount;
    }
  },
  components: {
    SidebarLink
  }
};
</script>
